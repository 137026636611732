import * as React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { MenuItem } from '@mui/material'
import { TextColor } from '@controls/Text'
import { LanguageButtonStyled, LanguageDropdownStyled } from './LanguageDropdown.styles'

type LanguageDropdownProps = {
  color: TextColor
}

export const LanguageDropdown = ({ color }: LanguageDropdownProps): React.ReactElement => {
  const { t, i18n } = useTranslation()

  const onChangeLanguage = (language: string) => (): void => {
    void i18n.changeLanguage(language)
  }

  const renderMenu = (): React.ReactNode =>
    i18n.languages.map((l) => (
      <MenuItem key={l} onClick={onChangeLanguage(l)}>
        {t(`app.languages.${l}`)}
      </MenuItem>
    ))

  return (
    <LanguageDropdownStyled renderMenu={renderMenu}>
      {(setAnchor, open) => (
        <LanguageButtonStyled
          className={classNames('language-dropdown', { open })}
          color={color}
          onClick={(e) => setAnchor(e.currentTarget)}
        >
          {t(`app.languages.${i18n.language}`)}
        </LanguageButtonStyled>
      )}
    </LanguageDropdownStyled>
  )
}
