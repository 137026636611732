import styled, { css } from 'styled-components'
import { Button } from '@controls/Button'
import { MenuTrigger } from '@controls/MenuTrigger'

export const LanguageButtonStyled = styled(Button)(
  ({ theme, color }) => css`
    border: 0;
    color: ${color};
    font-size: ${theme.global.typography.size['size-14']};
  `
)

export const LanguageDropdownStyled = styled(MenuTrigger)(
  ({ theme }) => css`
    li {
      font-size: ${theme.global.typography.size['size-14']};
    }

    .MuiPaper-root {
      box-shadow: none;
      border-radius: 0;
    }
  `
)
